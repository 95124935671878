import * as AxiosLogger from 'axios-logger';
import enviroment from '../../enviroments/enviroment';
import { IUserMe } from '../interfaces/user';

const InterceptorConfig = async (config: any) => {
    const { url } = config;
    let urlRest = url.replace(enviroment.apiURL, '');
    const arrUrl = urlRest.split('/');

    if (Number(arrUrl[arrUrl.length - 1])) {
        urlRest = urlRest.replace('/' + arrUrl[arrUrl.length - 1], '');
    }

    const itemUserMe = (await localStorage.getItem('userme')) || '';
    const userMe = itemUserMe
        ? (JSON.parse(itemUserMe) as IUserMe)
        : ({} as IUserMe);
    const hasAuthorization = [
        '/checklists',
        '/checklists/dashboard',
        '/checklists/dashboard_detail',
        '/checklists/dashboard_websearch',
        '/checklists/dashboard_websearch_xls',
        '/checklists/listar_visual_resultadoweb',
        '/checklists/listar_visual_resultadoweb_xls',
        '/checklists/visual_promedioweb',
        '/checklists/visual_promedioweb_xls',
        '/checklists/visual_resumenweb',
        '/checklists/visual_resumenweb_xls',
        '/entidad/estados',
        '/incidencias/web_search',
        '/incidencias/detalle_byid',
        '/incidencias/actualizar_atencion',
        '/incidencias/web_search_xls',
        '/albumfoto/listar',
        '/mantenimiento/listar_web',
        '/mantenimiento/by_id',
        '/mantenimiento/actualizar_web',
        '/mantenimiento/listar_web_xls',
        '/cuadratura/search_web',
        '/cuadratura/listagrupo',
        '/cuadratura/listadetalle_web',
        '/cuadratura/search_web_xls',
        '/cuadratura/diferencias_listaweb',
        '/cuadratura/diferencias_listaweb_xls',
        '/extorno/listar_web',
        '/extorno/listar_web_xls',
        '/extorno/by_id',
        '/extorno/actualizar_web',
        '/cuadratura/listaboveda_web',
        '/cuadratura/listaboveda_web_xls',
        '/auditoria/listar_web',
        '/auditoria/listar_web_xls',
        '/auditoria/resumenweb',
        '/mantenimiento/estadisticaweb',
        '/zonas/jefes',
        '/zonas/listar',
        '/contrato/listar',
        '/contrato/exportar_lista_xls',
        '/contrato/by_id',
        '/contrato/crear'
    ];
    const token = userMe ? userMe?.token : '';

    if (hasAuthorization.join().indexOf(urlRest) > -1) {
        config.headers.authorization = `Bearer ${token}`;
    }

    return AxiosLogger.requestLogger(config);
};

const InterceptorError = (err: any) => {
    return AxiosLogger.errorLogger(err);
};

const InterceptorResponseConfig = async (config: any) => {
    // console.log(config, '<<< InterceptorResponseConfig config');
    return AxiosLogger.responseLogger(config);
};

const InterceptorResponseError = (err: any) => {
    // console.log(err, '<<< InterceptorResponseError error');
    // const originalConfig = err.config;

    // if (originalConfig.url !== '/auth/signin' && err.response) {
    // // Access Token was expired
    // if (err.response.status === 401 && !originalConfig._retry) {
    //   originalConfig._retry = true;
    //   try {
    //     const rs = await instance.post("/auth/refreshtoken", {
    //       refreshToken: TokenService.getLocalRefreshToken(),
    //     });
    //     const { accessToken } = rs.data;
    //     TokenService.updateLocalAccessToken(accessToken);
    //     return instance(originalConfig);
    //   } catch (_error) {
    //     return Promise.reject(_error);
    //   }
    // }
    // }

    return AxiosLogger.errorLogger(err);
};

export {
    InterceptorConfig,
    InterceptorError,
    InterceptorResponseConfig,
    InterceptorResponseError,
};
