import PackageJSON from '../../package.json';

const enviroment = {
    // apiURL: 'https://8t4y256md0.execute-api.us-east-1.amazonaws.com/api',
    apiURL: 'https://wj7m7mqw87.execute-api.us-east-1.amazonaws.com/api',
    reCaptcha: '6LeF8igbAAAAAPxfvjHYnNdeFeAeuQ16_oic2BEU',
    search: {
        limitPerPage: 20,
        formatDate: 'YYYYMMDD',
    },
    isCaptcha: false,
    version: PackageJSON.version,
};

export default enviroment;
