import View53 from '../../bussines/submodule/view53/View53';
import View54 from '../../bussines/submodule/view54/View54';
import View55 from '../../bussines/submodule/view55/View55';
import View56 from '../../bussines/submodule/view56/View56';
import View57 from '../../bussines/submodule/view57/View57';
import View58 from '../../bussines/submodule/view58/View58';
import View59 from '../../bussines/submodule/view59/View59';
import View60 from '../../bussines/submodule/view60/View60';
import View62 from '../../bussines/submodule/view62/View62';
import View63 from '../../bussines/submodule/view63/View63';
import View64 from '../../bussines/submodule/view64/View64';
import View65 from '../../bussines/submodule/view65/View65';
import View66 from '../../bussines/submodule/view66/View66';
import View67 from '../../bussines/submodule/view67/View67';

export interface ISubModules {
    idSubmodule: number | string | null;
    view: any;
    breadcrumb?: string;
}

export const SubModules: Array<ISubModules> = [
    {
        idSubmodule: 53,
        view: View53,
        breadcrumb: 'Dashboard checklist',
    },
    {
        idSubmodule: 54,
        view: View54,
        breadcrumb: 'Informes de CheckList',
    },
    {
        idSubmodule: 55,
        view: View55,
        breadcrumb: 'Informes de Recepción',
    },
    {
        idSubmodule: 56,
        view: View56,
        breadcrumb: 'Listado de Mantenimientos',
    },
    {
        idSubmodule: 57,
        view: View57,
        breadcrumb: 'Listado de Cuadres',
    },
    {
        idSubmodule: 58,
        view: View58,
        breadcrumb: 'Listado de Extornos',
    },
    {
        idSubmodule: 59,
        view: View59,
        breadcrumb: 'Listado de Cuadres de Bóveda',
    },
    {
        idSubmodule: 60,
        view: View60,
        breadcrumb: 'Listado de Auditoría',
    },
    {
        idSubmodule: 62,
        view: View62,
        breadcrumb: 'Listado de Diferencias Cuadratura de Caja y Bóveda',
    },
    {
        idSubmodule: 63,
        view: View63,
        breadcrumb: 'Reportes Mantenimiento y Servicio',
    },
    {
        idSubmodule: 64,
        view: View64,
        breadcrumb: 'Cuadro Resumen Checklist Revisión Visual',
    },
    {
        idSubmodule: 65,
        view: View65,
        breadcrumb: 'Informe Resultado Checklist Revisión Visual',
    },
    {
        idSubmodule: 66,
        view: View66,
        breadcrumb: 'Informe Promedio Evaluación Visual',
    },
    {
        idSubmodule: 67,
        view: View67,
        breadcrumb: 'Estatus Tienda - Estado de Contratos con Tienda',
    },
];

export const Errors = {
    generic: 'Ocurrio un error inesperado. Vuelva a intentarlo.',
};

export const Success = {
    generic: 'Se guardo correctamente.',
};
