import { createContext, useState } from 'react';
import { IUser } from '../interfaces/user';
import {
    IModalPhoto,
    IPayload53,
    IPayload54,
    IPayload55,
    IPayload56,
    IPayload57,
    IPayload58,
    IPayload59,
    IPayload60,
    IPayload62,
    IPayload63,
    IPayload64,
    IPayload65,
    IPayload66,
    IPayload67,
    IPayload68
} from '../interfaces/modules';

type TStatus =
    | 'newuser'
    | 'inprogress'
    | 'validated'
    | 'connected'
    | 'disconnected';

interface IStateUser {
    user: IUser;
    tokenRC: string;
    status: TStatus;
    isLoading: boolean;
    showModal: boolean;
    showModalPhoto: boolean;
    dataModalPhoto: IModalPhoto;
    showModalEdit55: boolean;
    snackBar: string;
    urlBackTop: string;
    payload53: IPayload53 | null;
    payload54: IPayload54 | null;
    payload55: IPayload55 | null;
    payload56: IPayload56 | null;
    payload57: IPayload57 | null;
    payload58: IPayload58 | null;
    payload59: IPayload59 | null;
    payload60: IPayload60 | null;
    payload62: IPayload62 | null;
    payload63: IPayload63 | null;
    payload64: IPayload64 | null;
    payload65: IPayload65 | null;
    payload66: IPayload66 | null;
    payload67: IPayload67 | null;
    payload68: IPayload68 | null;
    createUser: Function;
    verifyTokenRC: Function;
    changeNewUser: Function;
    changeIsLoading: Function;
    changeShowModal: Function;
    changeShowModalPhoto: Function;
    changeShowModalEdit55: Function;
    changeShowModalEdit67: Function;
    changeShowModalEdit68: Function;
    changeDataModalPhoto: Function;
    changeSnackBar: Function;
    changeUrlBackTop: Function;
    changePayload53: Function;
    changePayload54: Function;
    changePayload55: Function;
    changePayload56: Function;
    changePayload57: Function;
    changePayload58: Function;
    changePayload59: Function;
    changePayload60: Function;
    changePayload62: Function;
    changePayload63: Function;
    changePayload64: Function;
    changePayload65: Function;
    changePayload66: Function;
    changePayload67: Function;
    changePayload68: Function;
}

interface IAppContext {
    children: any;
}

const initUser: IUser = {
    name: '',
    lastName: '',
    position: '',
    email: '',
    phone: '',
};

const initialState: IStateUser = {
    user: initUser,
    tokenRC: '',
    status: 'newuser',
    isLoading: false,
    showModal: false,
    showModalPhoto: false,
    showModalEdit55: false,
    dataModalPhoto: { photo: '', photos: [], initIndex: 0 },
    snackBar: '',
    urlBackTop: '',
    payload53: null,
    payload54: null,
    payload55: null,
    payload56: null,
    payload57: null,
    payload58: null,
    payload59: null,
    payload60: null,
    payload62: null,
    payload63: null,
    payload64: null,
    payload65: null,
    payload66: null,
    payload67: null,
    payload68: null,
    createUser: () => {},
    verifyTokenRC: () => {},
    changeNewUser: () => {},
    changeIsLoading: () => {},
    changeShowModal: () => {},
    changeShowModalPhoto: () => {},
    changeShowModalEdit55: () => {},
    changeShowModalEdit67: () => {},
    changeShowModalEdit68: () => {},
    changeDataModalPhoto: () => {},
    changeSnackBar: () => {},
    changeUrlBackTop: () => {},
    changePayload53: () => {},
    changePayload54: () => {},
    changePayload55: () => {},
    changePayload56: () => {},
    changePayload57: () => {},
    changePayload58: () => {},
    changePayload59: () => {},
    changePayload60: () => {},
    changePayload62: () => {},
    changePayload63: () => {},
    changePayload64: () => {},
    changePayload65: () => {},
    changePayload66: () => {},
    changePayload67: () => {},
    changePayload68: () => {},
};

const AppContext = createContext(initialState);

const AppContextProvider = ({ children }: IAppContext) => {
    let [user, setUser] = useState<IUser>(initUser);
    let [tokenRC, setTokenRC] = useState<string>('');
    let [status, setStatus] = useState<TStatus>('newuser');
    let [isLoading, setIsLoading] = useState<boolean>(false);
    let [showModal, setShowModal] = useState<boolean>(false);
    let [dataModalPhoto, setDataModalPhoto] = useState<IModalPhoto>({
        photo: '',
        photos: [],
        initIndex: 0,
    });
    let [showModalPhoto, setShowModalPhoto] = useState<boolean>(false);
    let [showModalEdit55, setShowModalEdit55] = useState<boolean>(false);
    let [showModalEdit67, setShowModalEdit67] = useState<boolean>(false);
    let [showModalEdit68, setShowModalEdit68] = useState<boolean>(false);
    let [snackBar, setSnackBar] = useState<string>('');
    let [urlBackTop, setUrlBackTop] = useState<string>('');
    let [payload53, setPayload53] = useState<IPayload53 | null>(null);
    let [payload54, setPayload54] = useState<IPayload54 | null>(null);
    let [payload55, setPayload55] = useState<IPayload55 | null>(null);
    let [payload56, setPayload56] = useState<IPayload56 | null>(null);
    let [payload57, setPayload57] = useState<IPayload57 | null>(null);
    let [payload58, setPayload58] = useState<IPayload58 | null>(null);
    let [payload59, setPayload59] = useState<IPayload59 | null>(null);
    let [payload60, setPayload60] = useState<IPayload60 | null>(null);
    let [payload62, setPayload62] = useState<IPayload62 | null>(null);
    let [payload63, setPayload63] = useState<IPayload63 | null>(null);
    let [payload64, setPayload64] = useState<IPayload64 | null>(null);
    let [payload65, setPayload65] = useState<IPayload65 | null>(null);
    let [payload66, setPayload66] = useState<IPayload66 | null>(null);
    let [payload67, setPayload67] = useState<IPayload67 | null>(null);
    let [payload68, setPayload68] = useState<IPayload68 | null>(null);

    const createUser = (u: IUser) => {
        setUser(u);
    };

    const verifyTokenRC = async (t: string) => {
        setTokenRC(t);
    };

    const changeNewUser = async (s: TStatus) => {
        setStatus(s);
    };

    const changeIsLoading = (l: boolean) => {
        setIsLoading(l);
    };

    const changeShowModal = (m: boolean) => {
        setShowModal(m);
    };

    const changeShowModalPhoto = (m: boolean) => {
        setShowModalPhoto(m);
    };

    const changeShowModalEdit55 = (m: boolean) => {
        setShowModalEdit55(m);
    };

    const changeShowModalEdit67 = (m: boolean) => {
        setShowModalEdit67(m);
    };

    const changeShowModalEdit68 = (m: boolean) => {
        setShowModalEdit68(m);
    };

    const changeDataModalPhoto = (f: IModalPhoto) => {
        setDataModalPhoto(f);
    };

    const changeSnackBar = (sn: string) => {
        setSnackBar(sn);
    };

    const changeUrlBackTop = (u: string) => {
        setUrlBackTop(u);
    };

    const changePayload53 = (json: IPayload53) => {
        setPayload53(json);
    };

    const changePayload54 = (json: IPayload54) => {
        setPayload54(json);
    };

    const changePayload55 = (json: IPayload55) => {
        setPayload55(json);
    };

    const changePayload56 = (json: IPayload56) => {
        setPayload56(json);
    };

    const changePayload57 = (json: IPayload57) => {
        setPayload57(json);
    };

    const changePayload58 = (json: IPayload58) => {
        setPayload58(json);
    };

    const changePayload59 = (json: IPayload59) => {
        setPayload59(json);
    };

    const changePayload60 = (json: IPayload60) => {
        setPayload60(json);
    };

    const changePayload62 = (json: IPayload62) => {
        setPayload62(json);
    };

    const changePayload63 = (json: IPayload63) => {
        setPayload63(json);
    };

    const changePayload64 = (json: IPayload64) => {
        setPayload64(json);
    };

    const changePayload65 = (json: IPayload65) => {
        setPayload65(json);
    };

    const changePayload66 = (json: IPayload66) => {
        setPayload66(json);
    };

    const changePayload67 = (json: IPayload67) => {
        setPayload67(json);
    };

    const changePayload68 = (json: IPayload68) => {
        setPayload68(json);
    };

    return (
        <AppContext.Provider
            value={{
                user,
                tokenRC,
                status,
                isLoading,
                snackBar,
                showModal,
                showModalPhoto,
                dataModalPhoto,
                showModalEdit55,
                createUser,
                urlBackTop,
                payload53,
                payload54,
                payload55,
                payload56,
                payload57,
                payload58,
                payload59,
                payload60,
                payload62,
                payload63,
                payload64,
                payload65,
                payload66,
                payload67,
                payload68,
                verifyTokenRC,
                changeNewUser,
                changeIsLoading,
                changeShowModal,
                changeShowModalPhoto,
                changeDataModalPhoto,
                changeShowModalEdit55,
                changeShowModalEdit67,
                changeShowModalEdit68,
                changeSnackBar,
                changeUrlBackTop,
                changePayload53,
                changePayload54,
                changePayload55,
                changePayload56,
                changePayload57,
                changePayload58,
                changePayload59,
                changePayload60,
                changePayload62,
                changePayload63,
                changePayload64,
                changePayload65,
                changePayload66,
                changePayload67,
                changePayload68,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };
